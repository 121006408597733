import React, { useEffect, useState } from 'react';

import { range } from 'lodash';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { FieldTextInput } from '../..';
import CustomCheckBox from '../../CustomCheckBox/CustomCheckBox';
import { filters, goalsLabels } from '../../../marketplace-custom-config';

const MultipleEnquiryForm = props => {
  const {
    css,
    validators,
    setListingIds,
    selectedWorktripp,
    formattedFavouriteListings,
    currentUser,
  } = props;

  const [selectedListings, setSelectedListings] = useState({});
  const companyName = currentUser?.attributes?.profile.publicData?.organisationName;
  const goals = selectedWorktripp?.goals
    ?.map(goal => goalsLabels.find(e => e.key == goal)?.title)
    ?.join(', ');
  const noOfParticipants = selectedWorktripp?.noOfParticipants;
  const startDate = new Date(selectedWorktripp?.startDate).toLocaleDateString('en-GB');
  const endDate = new Date(selectedWorktripp?.endDate).toLocaleDateString('en-GB');
  const worktrippListings = selectedWorktripp?.listings?.map(({ listing_id }) => listing_id) || [];
  const worktrippName = selectedWorktripp?.name;
  const MAX_CHARACTER_COUNT = 250;

  const validateTextarea = value => {
    // if (!value) {
    //   return 'This field is required';
    // }

    if (value) {
      const words = value.trim().split(/\s+/);
      if (value.length > MAX_CHARACTER_COUNT) {
        return 'Exceeded the maximum word count (MAX_CHARACTER_COUNT words)';
      }
    }

    return undefined; // No validation errors
  };

  useEffect(() => {
    if (formattedFavouriteListings?.length > 0) {
      setSelectedListings(
        Object.fromEntries(formattedFavouriteListings.map(listing => [listing.id.uuid, false]))
      );
    }
  }, [formattedFavouriteListings]);

  return (
    <div>
      {formattedFavouriteListings?.map((listing, index) => {
        const listingTypeOptions = filters.find(filter => filter.id == 'type').config.options;
        const listingtypeDisplay =
          listing.attributes.publicData.listingType == 'retreat'
            ? 'Retreat'
            : listingTypeOptions.find(({ key }) => key == listing.attributes.publicData.listingType)
                ?.label;

        return (
          <CustomCheckBox
            title={listing.attributes.title}
            subtitle={listingtypeDisplay}
            key={listing.id.uuid}
            checked={selectedListings[listing.id.uuid]}
            disabled={worktrippListings.includes(listing.id.uuid)}
            onChange={value => {
              setSelectedListings({
                ...selectedListings,
                [listing.id.uuid]: value,
              });
              setListingIds({
                ...selectedListings,
                [listing.id.uuid]: value,
              });
            }}
          />
        );
      })}

      <label style={{ display: 'flex' }}>
        <Field
          name="selectAll"
          component="input"
          type="checkbox"
          className={css.selectAllRadio}
          checked={Object.keys(selectedListings)
            .filter(
              listingId => !(worktrippListings?.length > 0 && worktrippListings.includes(listingId))
            )
            .every(listingId => selectedListings[listingId])}
          onClick={event => {
            setSelectedListings(
              Object.fromEntries(
                Object.entries(selectedListings).map(([key]) => {
                  if (worktrippListings.includes(key)) return [key, false];
                  return [key, event.currentTarget.checked];
                })
              )
            );
            setListingIds(
              Object.fromEntries(
                Object.entries(selectedListings).map(([key]) => {
                  if (worktrippListings.includes(key)) return [key, false];
                  return [key, event.currentTarget.checked];
                })
              )
            );
          }}
        />
        Select All
      </label>

      <Field
        name="message"
        render={({ input, meta }) => {
          return (
            <div>
              <label htmlFor={'Customise your message'} className={css.textAreaTitle}>
                Customise your message{' '}
              </label>
              <textarea {...input} maxLength={MAX_CHARACTER_COUNT} className={css.textAreaStyle} />
              <div className={css.textAreaMaxText}>
                {input.value.length} / {MAX_CHARACTER_COUNT}
              </div>
              {meta.touched && meta.error && <span>{meta.error}</span>}
            </div>
          );
        }}
        validate={validateTextarea}
      />

      <div style={{ margin: '6px 0px' }}>Your details</div>
      <div>
        {worktrippName && (
          <div className={css.labelText}>
            WorkTripp Name: <div className={css.labelSub}> {worktrippName}</div>
          </div>
        )}
        {companyName && (
          <div className={css.labelText}>
            Company: <div className={css.labelSub}> {companyName}</div>
          </div>
        )}
        <div className={css.labelText}>
          Goals: <div className={css.labelSub}> {goals}</div>
        </div>
        <div className={css.labelText}>
          Team size: <div className={css.labelSub}> {noOfParticipants} participant(s)</div>
        </div>
        <div className={css.labelText}>
          Dates:{' '}
          <div className={css.labelSub}>
            {' '}
            {startDate} - {endDate}
          </div>
        </div>
        {/* <div
        onClick={() => {
          
        }}
        style={{
          marginBottom: '21px',
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
      >
        <FormattedMessage id="MarketPopup.changeDetail" />
      </div> */}
      </div>
    </div>
  );
};

export default MultipleEnquiryForm;
