import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Button, IconButton, Modal } from '../..';
import { FormattedMessage } from '../../../util/reactIntl';

import css from './CheckAvailibilityPopup.module.css';

import * as validators from '../../../util/validators';

import { useDispatch } from 'react-redux';
import MultipleEnquiryForm from '../components/MultipleEnquiryForm';
import { sendEnquiry } from '../../../containers/ListingPage/ListingPage.duck';
import { editWorktripp } from '../../../containers/WorktrippPage/Worktripp.duck';
import { getFavouriteListings } from '../../../containers/SearchPage/SearchPage.duck';
import WorktrippOverViewForm from '../components/WorktrippOverViewForm';
import { DEFUALT_WORLD_ID } from '../../../default-location-searches';

import axios from 'axios';

const CheckAvailibilityPopup = props => {
  const {
    currentUser,
    toggleDisplay,
    setToggleDisplay,
    selectedWorktripp,
    formattedFavouriteListings,
    favouriteCart,
    onUpdateFavourites,
  } = props;

  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const executeScroll = () => scrollRef.current.scrollIntoView();

  const [isOpen, setIsOpen] = useState(false);
  const [goals, setGoals] = useState([]);
  const [location, setLocation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedListingIds, setListingIds] = useState(null);
  const [worktripp, setWorktripp] = useState(null);

  const singularLocation = useMemo(
    () => (location?.length === 1 ? location[0] : DEFUALT_WORLD_ID),
    [location]
  );

  const hasWorkTripp = !!selectedWorktripp?._id;
  const hideChat = isOpen => {
    const getElement = document.querySelectorAll('.crisp-client');

    getElement.forEach(element => {
      if (!isOpen) element.style.display = 'none';
      else element.style.display = 'block';
    });
  };
  useEffect(() => {
    if (toggleDisplay) {
      setIsOpen(true);
      setToggleDisplay(false);
      hideChat(isOpen);
    }
  }, [toggleDisplay]);

  useEffect(() => {
    if (selectedWorktripp._id) {
      setWorktripp({
        ...selectedWorktripp,
        startDate: new Date(
          selectedWorktripp.startDate?.date || selectedWorktripp.startDate
        ).toISOString(),
        endDate: new Date(
          selectedWorktripp.endDate?.date || selectedWorktripp.endDate
        ).toISOString(),
        goals: selectedWorktripp.goals,
        name: selectedWorktripp.name || selectedWorktripp.title,
        minBudget: selectedWorktripp.minBudget || selectedWorktripp.budgetPerPerson?.amount / 100,
        user_id: currentUser?.id?.uuid,
        email: currentUser ? currentUser?.attributes?.email : 'no email',
      });
      setGoals(selectedWorktripp.goals);
    }
  }, [selectedWorktripp]);

  let pages = [
    {
      title: 'You’re sending a message',
      body: (
        <MultipleEnquiryForm
          css={css}
          validators={validators}
          selectedListingIds={selectedListingIds}
          setListingIds={setListingIds}
          selectedWorktripp={worktripp}
          formattedFavouriteListings={formattedFavouriteListings}
          currentUser={currentUser}
        />
      ),
    },
  ];

  // Only show the Worktripp creation page if f
  if (!hasWorkTripp) {
    pages = [
      {
        title: "Let's start with the basics",
        body: (
          <WorktrippOverViewForm
            validators={validators}
            css={css}
            goals={goals}
            setGoals={setGoals}
            location={location}
            setLocation={setLocation}
          />
        ),
      },
      ...pages,
    ];
  }

  const [currentPage, setCurrentPage] = useState(1);

  const nextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, pages.length));
    executeScroll();
  };

  const previousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const onSubmit = async (values, proceedWithSignUp = true) => {
    let listingIds = [];
    if (selectedListingIds) {
      Object.entries(selectedListingIds).map(([key, value]) => {
        if (value) {
          listingIds.push(key);
        }
      });
    }

    return onSubmitEnquiry({
      message: values.message,
      wtRange: {
        startDate: new Date(worktripp.startDate?.date || worktripp.startDate).toISOString(),
        endDate: new Date(worktripp.endDate?.date || worktripp.endDate).toISOString(),
      },
      listingsToEnquire: listingIds,
      infoToShare: ['companyName', 'workTrippDescription', 'noOfParticipants'],
    });
  };
  const updateWorktripp = values => {
    if (!worktripp?._id) {
      setWorktripp({
        ...values,
        startDate: new Date(values.startDate?.date),
        endDate: new Date(values.endDate?.date),
        goals,
        location: singularLocation,
        name: values.title,
        minBudget: values.budgetPerPerson.amount / 100,
        user_id: currentUser.id.uuid,
      });
    } else {
      editWorktripp({
        _id: worktripp._id,
        ...values,
        startDate: new Date(values.startDate?.date),
        endDate: new Date(values.endDate?.date),
        goals,
        user_id: currentUser.id.uuid,
      });
    }
  };

  async function onSubmitEnquiry(values) {
    try {
      setLoading(true);
      const { message, wtRange, listingsToEnquire, infoToShare } = values;
      // Note: absolute madness going on here, will just make it work for adding location...
      let worktrippWithLocation = { ...worktripp, location: singularLocation };
      let worktrippForOffer = worktrippWithLocation;
      const worktrippId = worktrippForOffer._id;

      if (!worktrippId) {
        const { data } = await axios.post(`/api/worktripp/create`, worktrippWithLocation, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        });
        worktrippForOffer = data;
        setWorktripp(data);
      }

      let listingsToBeRemovedFromFavourites = [];
      if (listingsToEnquire && listingsToEnquire.length) {
        let txAndListingIds = [];
        const listingPromises = listingsToEnquire.map(async ls => {
          const listingId = ls;
          const dates = wtRange
            ? wtRange
            : {
                startDate: new Date(worktrippForOffer.startDate).toISOString(),
                endDate: new Date(worktrippForOffer.endDate).toISOString(),
              };
          delete worktrippForOffer.email;
          await dispatch(
            sendEnquiry(
              listingId,
              message.trim(),
              dates,
              worktrippForOffer,
              infoToShare,
              currentUser.id.uuid
            )
          )
            .then(async tx => {
              listingsToBeRemovedFromFavourites.push(listingId);
              txAndListingIds.push({ tx_id: tx.uuid, listing_id: listingId });
              setLoading(false);
            })
            .catch(err => {
              console.log(err);
              setLoading(false);
            });
        });

        await Promise.all(listingPromises);
        addListingToWorktripp(txAndListingIds, worktrippForOffer);
        setIsSubmit(true);
        const newListings = favouriteCart.filter(
          listingId => !listingsToBeRemovedFromFavourites.includes(listingId)
        );
        onUpdateFavourites(newListings, hasWorkTripp ? worktrippId : 'general');

        dispatch(getFavouriteListings(newListings));
      } else {
        setLoading(false);
      }
    } catch (err) {
      setErrorMessage(err.message);
      setLoading(false);
    }
  }

  function addListingToWorktripp(txAndListingIds, worktrippForOffer) {
    const worktrippListings =
      worktrippForOffer?.listings?.length > 0 ? worktrippForOffer.listings : [];
    const listings = [
      ...worktrippListings,
      ...txAndListingIds.map(({ tx_id, listing_id }) => {
        return {
          listing_id,
          tx_id,
        };
      }),
    ];

    return dispatch(
      editWorktripp({
        _id: worktrippForOffer._id,
        listings,
      })
    );
  }

  const checkDisabled = (values, selectedPage) => {
    if (loading) return true;
    const { noOfParticipants, startDate, endDate, budgetPerPerson, message, title } = values;
    const pageToCheck = selectedPage || currentPage;

    const multipleEnquiryCheck = !(
      message &&
      selectedListingIds &&
      Object.values(selectedListingIds).some(listing => listing)
    );
    const worktrippOverviewCheck = !(
      title &&
      noOfParticipants &&
      startDate &&
      endDate &&
      budgetPerPerson &&
      goals.length > 0
    );

    switch (pageToCheck) {
      case 1:
        return hasWorkTripp ? multipleEnquiryCheck : worktrippOverviewCheck;
      case 2:
        return multipleEnquiryCheck;
      default:
        return true;
    }
  };

  const submitModalPage = () => {
    return (
      <div style={{ padding: 40, textAlign: 'center' }}>
        <h1>
          Hooray! <span style={{ fontSize: '64px' }}>🚀</span>
        </h1>
        <h2>Your message has been sent</h2>
        <span>Verify your email to receive a notification when they reply.</span>
        <Button
          type="button"
          onClick={() => {
            window.open(`/viewWorktripp/${worktripp._id}`, '_top');
          }}
          className={classNames(css.buttonType, css.buttonSubmit)}
        >
          <FormattedMessage id="MarketPopup.viewWorktripp" />
        </Button>
      </div>
    );
  };

  const formModalPage = () => {
    const { title, body } = pages[currentPage - 1] || {};

    const renderDots = (form, values) => {
      if (pages.length <= 1) {
        return <div />;
      }
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {pages.map((page, pageIndex) => {
            let canProceed = true;
            for (var i = 0; i < pageIndex; i++) {
              const notAllValuesProvided = checkDisabled(values, i + 1);
              if (notAllValuesProvided) canProceed = false;
            }
            return (
              <span
                className={classNames(
                  css.dot,
                  pageIndex + 1 == currentPage && css.selected,
                  !canProceed && css.dotDisabled
                )}
                key={pageIndex + 1}
                onClick={() => {
                  if (canProceed) {
                    setCurrentPage(pageIndex + 1);
                    executeScroll();
                    form.change('page', pageIndex + 1);
                  }
                }}
              ></span>
            );
          })}
        </div>
      );
    };

    const renderNavButtons = (form, values) => {
      if (currentPage < pages.length) {
        return (
          <div className={classNames(css.setDisplayFlex, css.setFlexBottom)}>
            <div
              onClick={() => {
                setIsOpen(false);
                hideChat(true);
              }}
              style={{
                marginRight: '41px',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
            >
              <FormattedMessage id="ChatActionsSection.cancelButton" />
            </div>
            <Button
              type="button"
              onClick={() => {
                updateWorktripp(values);
                nextPage();
                form.change('page', currentPage + 1);
              }}
              disabled={checkDisabled(values)}
              className={css.buttonType}
            >
              <FormattedMessage id="RecommendationPopup.Next" />
            </Button>
          </div>
        );
      }

      return (
        <div className={css.buttonWrapper}>
          <Button
            type="submit"
            className={css.buttonType}
            style={{ marginLeft: '1rem', width: '100%', padding: '0px 20px' }}
            disabled={checkDisabled(values)}
          >
            <FormattedMessage id="SendMessageForm.sendMessage" />
          </Button>
        </div>
      );
    };

    return (
      <FinalForm
        onSubmit={onSubmit}
        initialValues={{
          message: `Hello! I\’m interested in your listing for our next offsite. 

Could you tell me your availability and pricing for our dates? 

Thanks!`,
        }}
        render={({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <h1 style={{ textAlign: 'center', fontSize: '30px', marginTop: '24px' }}>{title}</h1>
              <hr style={{ borderBottom: '1px solid #bbb' }}></hr>
            </div>
            <div className={css.formBody}>
              <div ref={scrollRef}></div>
              <div key={currentPage - 1}>{body}</div>
            </div>
            {errorMessage && (
              <div style={{ color: 'red', marginTop: '24px', marginBottom: '24px' }}>
                {errorMessage}
              </div>
            )}
            <div className={css.setBottomFlex}>
              {renderDots(form, values)}
              {renderNavButtons(form, values)}
            </div>
          </form>
        )}
      />
    );
  };

  return (
    <Modal
      id="CheckAvailibilityPopup"
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        setIsSubmit(false);
      }}
      onManageDisableScrolling={() => {}}
      closeButtonMessage="hidden"
      containerClassName={css.container}
    >
      {isSubmit ? submitModalPage() : formModalPage()}
    </Modal>
  );
};

export default CheckAvailibilityPopup;
