import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Button, IconButton, Modal } from '../..';
import { FormattedMessage } from '../../../util/reactIntl';

import css from './SignUpFormPopUp.module.css';

import * as validators from '../../../util/validators';

import { useDispatch, useSelector } from 'react-redux';
import { ACTION_AFTER_LOGIN, signup } from '../../../ducks/Auth.duck';
import SignUpForm from '../components/SignUpForm';

const SignUpFormPopUp = props => {
  const { currentUser, toggleDisplay, setToggleDisplay, selectedWorktripp, favouriteCart } = props;

  const dispatch = useDispatch();

  const { Auth: authReducer } = useSelector(state => state);
  const { signupError, loginErrorMsg } = authReducer;
  const scrollRef = useRef(null);
  const executeScroll = () => scrollRef.current.scrollIntoView();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);

  const hideChat = isOpen => {
    const getElement = document.querySelectorAll('.crisp-client');

    getElement.forEach(element => {
      if (!isOpen) element.style.display = 'none';
      else element.style.display = 'block';
    });
  };
  useEffect(() => {
    hideChat(toggleDisplay);
  }, [toggleDisplay]);

  useEffect(() => {
    if (loginErrorMsg) {
      setErrorMessage(loginErrorMsg);
      setLoading(false);
    } else if (signupError) {
      setErrorMessage(signupError);
      setLoading(false);
    }
  }, [signupError, loginErrorMsg]);

  let pages = [
    {
      title: 'Sign up to check availability',
      body: (
        <SignUpForm
          validators={validators}
          css={css}
          subTitle={'We need you to create an account in order to send & receive messages'}
        />
      ),
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);

  const nextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, pages.length));
    executeScroll();
  };

  const previousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const onSubmit = async (values, proceedWithSignUp = true) => {
    // Handle form submission logic
    setErrorMessage(null);

    if (loading) return;

    try {
      setLoading(true);
      const { email, password, firstName, lastName, organisationName, jobtitle } = values;

      dispatch(
        signup(
          {
            email,
            password,
            firstName,
            lastName,
            organisationName,
            jobtitle,
            interestedIn: 'Booking a WorkTripp',
          },
          selectedWorktripp?._id,
          favouriteCart?.length > 0 && 'showEnquiryForm'
        )
      );
    } catch (err) {
      setLoading(false);
      setErrorMessage(err?.message ? err.message : 'Failed to process the request');
      console.log(err);
    }
  };

  const checkDisabled = values => {
    if (loading) return true;
    const { firstName, lastName, email, password, organisationName, jobtitle } = values;
    switch (currentPage) {
      case 1:
        return !(firstName && lastName && email && password && organisationName && jobtitle);
      default:
        return true;
    }
  };

  return (
    <Modal
      id="SignUpFormPopUp"
      isOpen={toggleDisplay}
      onClose={() => {
        setToggleDisplay(false);
        setIsSubmit(false);
      }}
      onManageDisableScrolling={() => {}}
      closeButtonMessage="hidden"
      containerClassName={css.container}
    >
      {isSubmit && currentUser ? (
        <div>
          <div style={{ fontSize: '64px', textAlign: 'center', margin: '20px 0px' }}>🚀</div>
          <div
            style={{
              fontSize: '30px',
              fontWeight: 'bold',
              textAlign: 'center',
              padding: '20px 0px',
            }}
          >
            <div style={{ marginBottom: '40px' }}>You are logged in</div>
            <div style={{ width: '280px', margin: 'auto' }}>
              If not redirected, close this modal to continue browsing
            </div>
          </div>

          <Button
            type="button"
            onClick={() => {
              window.open(`/viewWorktripp/${selectedWorktripp._id}`, '_top');
            }}
            className={classNames(css.buttonType, css.buttonSubmit)}
          >
            <FormattedMessage id="MarketPopup.viewWorktripp" />
          </Button>
        </div>
      ) : (
        <FinalForm
          onSubmit={onSubmit}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>
              {pages[currentPage - 1]?.title && (
                <div>
                  <h1 style={{ textAlign: 'center', fontSize: '30px', marginTop: '24px' }}>
                    {pages[currentPage - 1]?.title}
                  </h1>
                  <hr style={{ borderBottom: '1px solid #bbb' }}></hr>
                </div>
              )}
              <div className={css.formBody}>
                <div ref={scrollRef}></div>
                <div key={currentPage - 1}>{pages[currentPage - 1]?.body}</div>
              </div>
              {errorMessage && (
                <div style={{ color: 'red', marginTop: '24px', marginBottom: '24px' }}>
                  {errorMessage}
                </div>
              )}
              <div className={css.setBottomFlex}>
                <div>
                  {pages.length > 1 &&
                    pages.map((page, pageIndex) => (
                      <span
                        className={classNames(
                          css.dot,
                          pageIndex + 1 == currentPage && css.selected
                        )}
                        key={pageIndex + 1}
                        onClick={() => {
                          setCurrentPage(pageIndex + 1);
                          executeScroll();
                          form.change('page', pageIndex + 1);
                        }}
                      ></span>
                    ))}
                </div>
                <div>
                  {currentPage < pages.length && (
                    <div className={classNames(css.setDisplayFlex, css.setFlexBottom)}>
                      <div
                        onClick={() => {
                          setToggleDisplay(false);
                          hideChat(true);
                        }}
                        style={{
                          marginRight: '41px',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                      >
                        <FormattedMessage id="ChatActionsSection.cancelButton" />
                      </div>
                      <Button
                        type="button"
                        onClick={() => {
                          nextPage();
                          form.change('page', currentPage + 1);
                        }}
                        disabled={checkDisabled(values)}
                        className={css.buttonType}
                      >
                        <FormattedMessage id="RecommendationPopup.Next" />
                      </Button>
                    </div>
                  )}

                  {currentPage === pages.length ? (
                    <div className={css.buttonWrapper}>
                      <Button
                        type="submit"
                        className={css.buttonType}
                        style={{ marginLeft: '1rem', width: '100%', padding: '0px 20px' }}
                        disabled={checkDisabled(values)}
                      >
                        <FormattedMessage id="SignupForm.signUp" />
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>
            </form>
          )}
        />
      )}
    </Modal>
  );
};

export default SignUpFormPopUp;
