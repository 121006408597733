import { func, string } from 'prop-types';
import React, { useState } from 'react';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import css from './CustomCheckBox.module.css';
import { Field } from 'react-final-form';
import classNames from 'classnames';

const CustomCheckBox = props => {
  const { title, subtitle, checked, onChange, disabled } = props;

  return (
    <label
      className={disabled ? classNames(css.disabledWrapper, css.setComponent) : css.setComponent}
    >
      {disabled && <span className={css.disabledMessage}>Already included in WorkTripp</span>}
      <Field
        type="checkbox"
        component="input"
        className={css.checkboxLabel}
        name={title}
        id={title}
        disabled={disabled}
        render={<input type="checkbox" value={title} disabled={disabled} />}
        checked={checked}
        onChange={() => onChange?.(!checked)}
      />

      <div className={classNames(css.checkBoxTitle, css.textFlex)}>
        <div className={css.middleText}>{title}</div>
        <div className={css.lastText}>{subtitle}</div>
      </div>
    </label>
  );
};

CustomCheckBox.defaultProps = {
  title: null,
  children: null,
};

CustomCheckBox.propTypes = {
  title: string,
  onCheckboxChange: func,
};

export default CustomCheckBox;
