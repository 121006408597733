import React from 'react';
import css from './FavouritesListingCard.module.css';
import NoImageDefault from './NoImageDefault.png';
import { formatMoney } from '../../../../util/currency';
import { filters } from '../../../../marketplace-custom-config';

const FavouritesListingCard = props => {
  const { listing, intl, onRemoveListingFromFavourites } = props;
  const { attributes, images, id } = listing;
  const { title, price, publicData } = attributes || {};
  const { listingType } = publicData || {};

  const listingImage = images[0]?.attributes?.variants['square-small']?.url || NoImageDefault;
  const displayPrice = price ? formatMoney(intl, price, true) : 'N/A';
  const listingTypeOptions = filters.find(filter => filter.id == 'type').config.options;
  const listingtypeDisplay =
    listingType == 'retreat'
      ? 'Retreat'
      : listingTypeOptions.find(({ key }) => key == listingType)?.label;

  return (
    <div className={css.card}>
      <img src={listingImage} className={css.listingImage} />
      <div className={css.detailsWrapper}>
        <span className={css.title}>
          {title.length < 35 ? title : `${title.substring(0, 35)}...`}
        </span>
        <span className={css.price}>{displayPrice} per person, per day</span>
      </div>
      <div className={css.actionWrapper}>
        <span className={css.listingTypeLabel}>{listingtypeDisplay}</span>
        <a className={css.removeAction} onClick={() => onRemoveListingFromFavourites(id.uuid)}>
          Remove
        </a>
      </div>
    </div>
  );
};

export default FavouritesListingCard;
