import React, { useEffect, useState } from 'react';
import { Button, FieldSelect, IconSpinner, ModalInMobile } from '../../../components';
import css from './FavouritesCart.module.css';
import heartIcon from './heartIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getFavouriteListings } from '../SearchPage.duck';
import { attachAuthorAndImagesToListings } from '../../../util/misc';
import FavouritesListingCard from './FavouritesListingCard/FavouritesListingCard';
import { selectWorktripp } from '../../WorktrippPage/Worktripp.duck';
import CheckAvailibilityPopup from '../../../components/PopUpForms/CheckAvailibilityPopup/CheckAvailibilityPopup';
import SignUpFormPopUp from '../../../components/PopUpForms/SignUpFormPopUp/SignUpFormPopUp';
import { RESET_ACTION_AFTER_LOGIN } from '../../../ducks/Auth.duck';

const FavouritesCart = props => {
  const {
    onManageDisableScrolling,
    currentUser,
    intl,
    onUpdateFavourites,
    isCartModalOpen,
    toggleCartModal,
    selectedWorktripp,
    setSelectedWorktripp,
    location,
    showEnquiryForm,
    setShowEnquiryForm,
  } = props;

  const dispatch = useDispatch();
  const {
    SearchPage: searchPageReducer,
    Worktripp: worktrippReducer,
    Auth: authReducer,
  } = useSelector(state => state);
  const {
    favouriteListings,
    getFavouritesInProgress,
    updateFavouritesInProgress,
  } = searchPageReducer;
  const { worktrippslist } = worktrippReducer;
  const { actionAfterLogin } = authReducer;

  const [loading, setLoading] = useState(false);
  const [showSignUpForm, setShowSignUpForm] = useState(false);

  const unparsedFavouriteListings =
    typeof window !== 'undefined' && window.localStorage.getItem('favouriteListings');
  const parsedFavourtiteListings = unparsedFavouriteListings
    ? JSON.parse(unparsedFavouriteListings)
    : [];
  const userFavouriteListings =
    currentUser?.attributes?.profile?.publicData?.favoriteListings || {};

  const isLoggedIn = currentUser?.id?.uuid;

  const getFavourites = () => {
    if (currentUser) {
      if (selectedWorktripp?._id) {
        return userFavouriteListings[selectedWorktripp._id] || [];
      } else {
        return userFavouriteListings?.general || [];
      }
    } else {
      return parsedFavourtiteListings;
    }
  };

  const favouriteCart = getFavourites();

  const onRemoveListingFromFavourites = listingId => {
    const newListings = favouriteCart.filter(l => l !== listingId);

    if (currentUser) {
      setLoading(true);
      const cartToLoad = selectedWorktripp?._id || 'general';
      onUpdateFavourites(newListings, cartToLoad);
    } else {
      window.localStorage.setItem('favouriteListings', JSON.stringify(newListings));
      window.dispatchEvent(new Event('storage'));
    }
    dispatch(getFavouriteListings(newListings));
  };

  const mergeLocalStorageFavouritesWithUserFavourites = parsedFavourtiteListings => {
    const generalFavouriteListings = userFavouriteListings?.general || [];
    if (selectedWorktripp?._id) {
      const worktrippFavouriteListings = userFavouriteListings[selectedWorktripp._id] || [];
      let requiresUpdate = false;
      let mergedFavouriteListings = [];
      if (parsedFavourtiteListings.length > 0) {
        mergedFavouriteListings = [...mergedFavouriteListings, ...parsedFavourtiteListings];
        window.localStorage.removeItem('favouriteListings');
        requiresUpdate = true;
      }
      if (generalFavouriteListings.length > 0) {
        mergedFavouriteListings = [...mergedFavouriteListings, ...generalFavouriteListings];
        requiresUpdate = true;
      }
      if (requiresUpdate) {
        mergedFavouriteListings = [
          ...new Set([...mergedFavouriteListings, ...worktrippFavouriteListings]),
        ];
        onUpdateFavourites(mergedFavouriteListings, selectedWorktripp._id, true);
      }
    } else if (parsedFavourtiteListings.length > 0) {
      const mergedFavouriteListings = [
        ...new Set([...parsedFavourtiteListings, ...generalFavouriteListings]),
      ];
      window.localStorage.removeItem('favouriteListings');
      onUpdateFavourites(mergedFavouriteListings, 'general');
    }
  };

  useEffect(() => {
    dispatch(getFavouriteListings(favouriteCart));
    if (currentUser) {
      mergeLocalStorageFavouritesWithUserFavourites(parsedFavourtiteListings);
    }
  }, [currentUser, selectedWorktripp]);

  useEffect(() => {
    if (!updateFavouritesInProgress) {
      setLoading(updateFavouritesInProgress);
    }
  }, [getFavouritesInProgress]);

  useEffect(() => {
    if (actionAfterLogin == 'showEnquiryForm') {
      setShowSignUpForm(false);
      setShowEnquiryForm(true);

      dispatch({
        type: RESET_ACTION_AFTER_LOGIN,
      });
    }
  }, [actionAfterLogin]);

  const onSelectWorktripp = selectedId => {
    const allIds = worktrippslist.map(w => w._id).filter(id => id !== selectedId);
    dispatch(selectWorktripp({ selectedId, allIds }));
  };

  const formattedFavouriteListings = attachAuthorAndImagesToListings(favouriteListings);

  return (
    <>
      <ModalInMobile
        className={css.cartShadow}
        containerClassName={css.cartPanelWrapper}
        id="SearchPaue.favouritesCart"
        isModalOpenOnMobile={isCartModalOpen}
        onClose={() => toggleCartModal(false)}
        showAsModalMaxWidth={768}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {getFavouritesInProgress || loading ? (
          <div className={css.loaderWrapper}>
            <IconSpinner />
          </div>
        ) : (
          <div>
            <div className={css.centralizedContentWrapper}>
              <img src={heartIcon} />
              <h3 className={css.title}>Your shortlist ({formattedFavouriteListings.length})</h3>
            </div>
            {worktrippslist?.length > 1 && (
              <>
                <label htmlFor="selectedWorktripp">Select a worktripp:</label>
                <select
                  onChange={e => {
                    onSelectWorktripp(e.target.value);
                  }}
                  value={selectedWorktripp?._id}
                  id="selectedWorktripp"
                  className={css.worktrippSelector}
                >
                  <option value="">Please select</option>
                  {worktrippslist.map(w => (
                    <option key={w._id} value={w._id}>
                      {w.name}
                    </option>
                  ))}
                </select>
              </>
            )}
            {formattedFavouriteListings.length > 0 ? (
              <div className={css.listingsWrapper}>
                {formattedFavouriteListings.map(listing => (
                  <FavouritesListingCard
                    listing={listing}
                    intl={intl}
                    formattedFavouriteListings={formattedFavouriteListings}
                    onRemoveListingFromFavourites={onRemoveListingFromFavourites}
                  />
                ))}
              </div>
            ) : (
              <div className={css.centralizedContentWrapper}>
                <span>No Listings</span>
              </div>
            )}
            {!currentUser && (
              <p>
                In order to book, please <a href="/login">login</a> or{' '}
                <a onClick={() => setShowSignUpForm(true)}>create an account</a> to check supplier
                availability and pricing.{' '}
              </p>
            )}
            {formattedFavouriteListings.length > 0 && (
              <Button
                className={css.enquireButton}
                onClick={() => (!isLoggedIn ? setShowSignUpForm(true) : setShowEnquiryForm(true))}
              >
                Check availability and pricing
              </Button>
            )}
          </div>
        )}
      </ModalInMobile>
      <CheckAvailibilityPopup
        currentUser={currentUser}
        toggleDisplay={showEnquiryForm}
        setToggleDisplay={setShowEnquiryForm}
        selectedWorktripp={selectedWorktripp}
        formattedFavouriteListings={formattedFavouriteListings}
        favouriteCart={favouriteCart}
        onUpdateFavourites={onUpdateFavourites}
      />
      <SignUpFormPopUp
        currentUser={currentUser}
        toggleDisplay={showSignUpForm}
        setToggleDisplay={setShowSignUpForm}
        location={location}
        favouriteCart={favouriteCart}
      />
    </>
  );
};

export default FavouritesCart;
